export const getUrlParams = (variable) => {
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    if(!vars[0]){
        return false;
    }

    let data = {};
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        data[pair[0]] = pair[1];
    }
    return data;
}
export const dateFormat = (dates) => {

    if(dates === undefined || !dates){
        return null;
    }
     let date = new Date(dates)
     let ret;
     let fmt = "YYYY-mm-dd HH:MM";
     const opt = {
         "Y+": date.getFullYear().toString(),        // 年
         "m+": (date.getMonth() + 1).toString(),     // 月
         "d+": date.getDate().toString(),            // 日
         "H+": date.getHours().toString(),           // 时
         "M+": date.getMinutes().toString(),         // 分
         "S+": date.getSeconds().toString()          // 秒
         // 有其他格式化字符需求可以继续添加，必须转化成字符串
     };
     for (let k in opt) {
         ret = new RegExp("(" + k + ")").exec(fmt);
         if (ret) {
             fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
         };
     };
 
     return fmt;
 }