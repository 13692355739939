<template>
  <div class="order">
    <div class="tabtop">
      <div class="searchtop">
        <div class="searchc">
          <van-search placeholder="请输入用户姓名/手机号码" />
        </div>
        <div class="clols" @click="onClick">
          <span>{{ btn }}</span>
        </div>
      </div>
      <van-tabs v-model:active="active" sticky @click-tab="ittabOnClick">
        <van-tab
          :name="it.status"
          v-for="(it, i) in ittab"
          :key="i"
          :title="it.title"
        />
      </van-tabs>
    </div>

    <div class="warpRef">
      <div class="item" v-for="(pcard, j) in data.list" :key="j">
        <div class="titls">
          <span>第{{ j + 1 }}条</span>
          <span>共{{ data.total }}条</span>
        </div>
        <van-card
          :price="pcard.order.loan_money / 10000 + ' w'"
          :desc="pcard.userInfo.phone_num"
          :title="pcard.userInfo.user_name"
          :thumb="pcard.userInfo.sex === 1 ? sexi.man : sexi.wman"
        >
          <template #tags>
            <van-tag plain type="danger">{{ title }}</van-tag>
            <p style="margin-top: 10; color: #666">
              {{ dateFormat(pcard.order.created_date) }}
            </p>
          </template>
          <template #footer>
            <div v-if="pcard.orderInfo.status === -3" class="descinfo">
              <span>
                {{ pcard.orderInfo.descz.replace(/<[^>]+>/g, "") }}
              </span>
            </div>
            <div v-if="pcard.orderInfo.status === -4" class="descinfo">
              <span style="color: red">
                {{
                  pcard.orderInfo.descz
                    ? pcard.orderInfo.descz.replace(/<[^>]+>/g, "")
                    : "准入决策被拒"
                }}
              </span>
            </div>
            <van-button @click="onCodeer(pcard)" v-if="pcard.orderInfo.status === 2" style="width: 100%"
              >二维码</van-button
            >
          </template>
        </van-card>
      </div>
      <div class="onMore" @click="onMore">
        <van-loading v-if="avicon" type="spinner" />
        <span v-else>{{ avtext }}</span>
      </div>
    </div>
  </div>
</template>


<script>
import { ref } from "vue";
import { dateFormat } from "../utls/index";
import { useRoute, useRouter } from "vue-router";
import { orderlist_api } from "../store/apis";

export default {
  setup() {
    const btn = ref("取消");
    const active = ref(2);
    const avicon = ref(false);
    const title = ref("待签约");
    const avtext = ref("点击加载更多");
    let asl = "";
    const ittab = [
      { title: "待签约", status: 2 },
      { title: "待核查", status: -3 },
      { title: "征信被拒", status: -4 },
      { title: "待面签", status: 4 },
    ];
    const params = {
      mixture: "",
      starDate: "",
      endDate: "",
      status: 0,
      current: 1,
      count: 5,
      ed: 0,
      st: 0,
      salesmanId: 0,
    };
    const router = useRouter();
    const route = useRoute();
    const data = ref({
      count: 0,
      current: 0,
      list: [],
      total: 0,
    });

    const orderlist = (params) => {
      avicon.value = true;
      orderlist_api(params).then((resp) => {
        console.log(resp);
        if (resp.code === 0) {
          avicon.value = false;
          data.value.count = resp.data.count;
          data.value.total = resp.data.total;
          data.value.current = resp.data.current;
          resp.data.list.forEach((item) => {
            data.value.list.push(item);
          });
          if (data.value.list.length === data.value.total) {
            avtext.value = "没有更多了";
          }
        }
      });
    };
    if (route.query.uid) {
      active.value = parseInt(route.query.status);
      params.status = route.query.status;
      params.salesmanId = route.query.uid;
      orderlist(params);
    }
    const ittabOnClick = (n) => {
      if (n.name !== asl) {
        data.value = {
          count: 0,
          current: 0,
          list: [],
          total: 0,
        };

        params.current = 1;
        params.status = n.name;
        orderlist(params);
        asl = n.name;
        title.value = n.title;
      }
    };


    const onMore = () => {
      params.current += 1;
      orderlist(params);
    };
    const onsearch = () => {
      router.push("search");
    };
    const onClick=()=>{
       router.go(-1);
    }
    const onCodeer = (pcard)=>{
      console.log(pcard);
       router.push({
         path:"/code",
         query:{
           orderNo:pcard.order.order_no,
           name:pcard.userInfo.user_name
         }
       });
    }
    return {
      btn ,
      ittab,
      avtext,
      avicon,
      active,
      title,
      sexi: {
        man: require("../assets/m.jpg"),
        wman: require("../assets/w.jpg"),
      },
      onCodeer,
      onMore,
      onsearch,
      ittabOnClick,
      dateFormat,
      onClick,
      data,
    };
  },
};
</script>


<style lang="scss" >
.order {
  .searchtop {
  display: flex;
  background-color: #fff;
  .searchc {
    width: 100%;
  }
  .clols {
    width: 70px;
    text-align: center;
    line-height: 50px;
    color: #576b95;
  }
}
  .titls {
    padding: 12px;
    font-size: 12px;
    color: #666;
    text-align: center;
  }
  .item {
    margin: 10px 0;

    box-shadow: 0 8px 12px #ebedf0;
    background-color: #f7f8fa;
    .titls {
      display: flex;
      justify-content: space-between;
      padding: 5px 20px;
    }

    .van-card__thumb {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .tabtop {
    width: 100%;
  }
  .warpRef {
    height: calc(100vh - 100px);
    overflow-y: scroll;
    .onMore {
      font-size: 12px;
      color: #666;
      text-align: center;
      height: 60px;
      line-height: 60px;
    }
    .descinfo {
      text-align: left;
      color: green;
    }
  }
}
</style>